import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Unity, { UnityContext } from 'react-unity-webgl';
import { SpinnerDotted } from 'spinners-react';

const unityContext = new UnityContext({
  loaderUrl: "build/build.loader.js",
  dataUrl: "build/build.data",
  frameworkUrl: "build/build.framework.js",
  codeUrl: "build/build.wasm",
});

interface AppState {
  displayUnity: boolean
  progress: number;
}
export default class App extends Component<any, AppState>{
  constructor(props: any) {
    super(props);
    this.state = {
      displayUnity: false,
      progress: 0
    }
  }
  componentDidMount() {
    unityContext.on("loaded", () => {
      console.log("unity finish loaded")
      setTimeout(() => this.setState({ displayUnity: true }), 3000)
    })
    unityContext.on("progress", (p) => {
      this.setState({
        progress: Math.floor(p * 99)
      })
    })
  }
  render(): React.ReactNode {
    return <div>
      <Unity style={{
        display: this.state.displayUnity ? "block" : "none",
        height: "100vh",
        width: "100vw",
        background: "grey",
      }} matchWebGLToCanvasSize unityContext={unityContext} />
      {
        this.state.displayUnity || <div style={{ position: "absolute",background:"#2c2c2c", left: 0, top: 0, width: "100vw", height: "100vh", margin: "auto", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <SpinnerDotted color='white'></SpinnerDotted>
        <div style={{color:"white",marginTop:8}}>正在加载中{this.state.progress}%...</div>
      </div>
      } 
    </div>;
  }
}

